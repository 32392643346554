import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js';
import LayoutFunnel from "../components/layouts/layoutFunnel.js"
import { Link as GatsbyLink } from 'gatsby'
import withLocation from "../utils/withLocation"
import portrait from '../images/portraitOlivierL.jpg'
import axios from 'axios'
import Seo from '../components/Seo/';
import { PartnerArticleTunnel } from '../components/PartnerArticleTunnel/index'

import {
    Box,
    Button,
    Divider,
    Flex,
    HStack,
    Text,
    Heading,
    Input,
    Image,
    List,
    ListItem,
    Stack,
    SimpleGrid,

    FormControl,
    FormLabel,
    Grid,
    Spinner,
    FormHelperText,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel
} from '@chakra-ui/react'

import { BiX, BiLeftArrowAlt, BiCheck } from 'react-icons/bi';

import { FormattedMessage, useIntl } from 'react-intl';
import { PartnerArticleTunnelForm } from '../components/PartnerArticleTunnel/Form';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const contentful = require("contentful");

//const translations = useIntl()['messages']

const client = contentful.createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE,
    environment: "master",
    accessToken: process.env.GATSBY_CONTENTFUL_TOKEN
});

const siteConfig = require('../../config')

const title = {
    "fr": "J'en appelle à votre générosité, Capitaine"
    ,
    "en": "I appeal to your generosity, Captain."
}
const description = {
    "fr": "Si vous souhaitez faire un petit don pour le fonctionnement du service, c'est ici."
    ,
    "en": "If you wish to make a small donation for the operation of the service, it is here."
};

const TipPaymentTemplate = (props) => {


    const stripePromise = loadStripe(process.env.GATSBY_STRIPE_CLIENT_KEY);
    const [ad, setAd] = useState(false)
    const [model, setModel] = useState(false)
    const [user, setUser] = useState(false)
    const [customPrice, setCustomPrice] = useState(false)
    const [isIniting, setIsIniting] = useState(false)

    const [partnerArticleFormVisible, setPartnerArticleFormVisible] = useState(false);

    useEffect(() => {
        if (!ad && props.search.ad) {
            axios.get(`${process.env.GATSBY_API_URL}/annonce/${props.search.ad}`)
                .then(function (response) {
                    setAd(response.data)
                    getModelDetails(response.data.refModel)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        }
    });


    const getModelDetails = (modelID) => {
        client.getEntry(modelID, { locale: process.env.GATSBY_LANG }).then(function (entry) {
            console.log('entry.fields', entry.fields)
            setModel(entry.fields);
        })

    }

    // const handleGoStripe = async (stripeProduct, amount) => {
    //     const email = ad.user  ? { customerEmail: ad.user.email } : null
    //     console.log( stripeProduct )
    //     console.log( ad.user )
    //     const stripe = await stripePromise;
    //     const { error } = await stripe.redirectToCheckout({
    //         mode: 'payment',
    //         lineItems: [{ price: stripeProduct, quantity:1 }],
    //         successUrl: `${siteConfig.domains[ process.env.GATSBY_LANG ]}${siteConfig.links.donationThankyou[ process.env.GATSBY_LANG ]}?session_id={CHECKOUT_SESSION_ID}&adID=${ad._id}&amount=${amount}`,
    //         cancelUrl: `${siteConfig.domains[ process.env.GATSBY_LANG ]}${props.pageContext.slug}?ad=${props.search.ad}`,
    //         ...email
    //     })
    //     if( error ){
    //         console.log( error )
    //     }
    // }

    const handleGoStripe = async (amount) => {
        const stripe = await stripePromise;
        setIsIniting(true)
        axios.post(
            `${process.env.GATSBY_API_URL}/payment/init`,
            {
                amount: amount * 100,
                user: ad.user,
                ad: ad,
                context: 'ad-deletion',
                successUrl: `${siteConfig.domains[process.env.GATSBY_LANG]}${siteConfig.links.donationThankyou[process.env.GATSBY_LANG]}?session_id={CHECKOUT_SESSION_ID}&adID&amount=${amount}`,
                cancelUrl: `${siteConfig.domains[process.env.GATSBY_LANG]}${props.pageContext.slug}?ad=${props.search.ad}`,
            }
        ).then(async (response) => {
            const result = await stripe.redirectToCheckout({
                sessionId: response.data.id,
            });
        })
    }

    const products = [
        {
            'name': 'Don Wanaboat.fr',
            'price': 9,
            'legend': <FormattedMessage id="donation.price.1.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_1,
            'color': 'blue.brand'
        },
        {
            'name': 'Don Wanaboat.fr',
            'price': 14,
            'legend': <FormattedMessage id="donation.price.2.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_2,
            'color': 'blue.brand'
        },
        {
            'name': 'Don Wanaboat.fr',
            'price': 24,
            'legend': <FormattedMessage id="donation.price.3.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_3,
            'color': 'blue.brand'
        },
        {
            'name': 'Don Wanaboat.fr',
            'price': 49,
            'legend': <FormattedMessage id="donation.price.4.notice" />,
            'stripePriceID': process.env.GATSBY_STRIPE_PRICE_3,
            'color': 'blue.brand'
        }
    ]
    const PriceCompareToAdPrice = (productPrice) => {
        const adPrice = ad.price
        const percent = productPrice / adPrice * 100
        return (Math.round((percent + Number.EPSILON) * 100) / 100)
    }

    const handleRefuse = () => {
        navigate('..')
    }

    return (
        <LayoutFunnel>
            {/* <PartnerArticleTunnel /> */}
            <Seo
                title={title[process.env.GATSBY_LANG]}
                description={description[process.env.GATSBY_LANG]}
                slug={props.pageContext.slug}
            />
            <Box
                p={6}
            >
                <Button
                    leftIcon={<BiLeftArrowAlt />}
                    as={GatsbyLink}
                    to={'../.'}
                >
                    <FormattedMessage id='main.back' />
                </Button>
            </Box>
            <Flex
                justifyContent="center"
                alignItems="center"
                minH='100vh'
            >
                <Stack
                    maxW='740px'
                    spacing='1.5rem'
                    px='1rem'
                    py={{ base: '2rem', lg: '4rem' }}
                    background='white'
                >
                    <Heading
                        as="h1"
                        fontSize={{ base: '3xl', lg: '5xl' }}
                        color="blue.brand"
                    ><FormattedMessage id='donation.title' />&nbsp;?</Heading>
                    <Image
                        src={portrait}
                        alt="Le Capitaine de Wanaboat"
                    />
                    <Text
                        color="gray.700"
                        fontSize="18px"
                    >
                        <FormattedMessage id='donation.notice.1' />
                    </Text>
                    <Box>
                        <Accordion allowMultiple>
                            <AccordionItem>
                                <AccordionButton
                                    bgColor='green.50'
                                >
                                    <Box flex='1' textAlign='left' fontSize={'sm'}>
                                        Vous faites partie d'un club ou d'une asso de classe ?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    <Stack spacing={6}>
                                        {!partnerArticleFormVisible ?
                                            <>
                                                <Text>
                                                    Vous pouvez sûrement aider Wanaboat en proposant un article présentant le service sur le site de votre club ou votre classe. Cela parrait anodin, mais c'est une grande aide pour la renommée du site. J'ai une trame toute prête à proposer, vous n'avez qu'à cliquer ici et remplir 2 infos, puis, je vous recontacterai pour vous détailler la manœuvre :
                                                </Text>
                                                <Button
                                                    whiteSpace={'break-spaces'}
                                                    variant='link'
                                                    onClick={() => setPartnerArticleFormVisible(true)}
                                                >🤝 oui, je peux publier un article sur le site mon club / ma classe</Button>
                                            </>
                                            :
                                            <PartnerArticleTunnelForm
                                                handleClose={() => setPartnerArticleFormVisible(false)}
                                            />
                                        }
                                    </Stack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                    <Text>
                        <FormattedMessage id='donation.notice.2' />
                    </Text>

                    <Box
                        bg='yellow.100'
                        borderLeft='solid 3px'
                        borderLeftColor='yellow.200'
                        p={{ base: 2, lg: 4 }}
                        borderRadius={{ base: 0, lg: 5 }}
                    >
                        <Text
                            fontWeight='bold'
                        >
                            <FormattedMessage id='donation.will.be.used' />
                        </Text>
                        <List>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.will.pay.charges' />
                            </ListItem>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.will.upgrade.service' />
                            </ListItem>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.will.keep.service.without.advert' />
                            </ListItem>
                            <ListItem>
                                <Text color="green.500" fontSize='22px' as='span'>✓</Text>
                                <FormattedMessage id='donation.have.a.drink' />
                            </ListItem>
                        </List>
                    </Box>


                    {!isIniting ?
                        <Stack
                            spacing='1.5rem'
                        >
                            <SimpleGrid
                                columns={{ base: 2, md: 4 }}
                                gap="1rem"
                            >
                                {products.map((product, index) =>
                                    <React.Fragment
                                        key={`${product.name}-${index}`}
                                    >
                                        <Flex
                                            border='solid 2px'
                                            borderColor='blue.brand'
                                            boxShadow='md'
                                            p={{ base: '.5rem', lg: '1rem' }}
                                            minH={{ base: 'auto', lg: '135px' }}
                                            textAlign='center'
                                            borderRadius='lg'
                                            cursor='pointer'
                                            transition='transform 250ms ease'
                                            onClick={() => handleGoStripe(product.price)}
                                            _hover={{
                                                transform: 'scale3d(1.05,1.05,1.05)',
                                                backgroundColor: 'blue.50'
                                            }}
                                            wrap='wrap'
                                            justify={'center'}
                                        >
                                            <Box w='full'>
                                                <Text color="blue.brand" fontWeight="bold" fontSize="24px">
                                                    {product.price}€
                                                </Text>
                                                <Text fontSize={{ base: '12px', lg: '14px' }}>
                                                    {product.legend}
                                                </Text>
                                            </Box>

                                            {ad ?
                                                <Box w='full'>
                                                    <Text textAlign='center' color='green.600' mt='.75rem' fontWeight='bold' fontSize={{ base: '10px', lg: '12px' }}>
                                                        <Text
                                                            as="span"
                                                            background="linear-gradient(270deg,rgba(255,255,255,0.48) 0%,rgba(255,255,255,0.221538) 53.85%,rgba(255,255,255,0) 100%),rgba(253,255,148,0.69)"
                                                        >
                                                            {PriceCompareToAdPrice(product.price)}%
                                                        </Text>&nbsp;
                                                        <FormattedMessage id="donation.of.the.price" />
                                                        <br />
                                                        {model ?
                                                            <>
                                                                <FormattedMessage id="donation.of.your" /> {model.name}
                                                            </>
                                                            : null}

                                                    </Text>
                                                </Box>
                                                : null}

                                        </Flex>

                                    </React.Fragment>

                                )}
                            </SimpleGrid>



                            <Divider />
                            <Box
                                as='form'
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleGoStripe(customPrice)
                                }}
                            >
                                <FormControl>
                                    <FormLabel><FormattedMessage id="donation.custom.notice" />&nbsp;:</FormLabel>
                                    <HStack>
                                        <Box>
                                            <Input
                                                type="number"
                                                w='60px'
                                                min={1}
                                                value={customPrice ? customPrice : ''}
                                                onChange={(e) => setCustomPrice(e.target.value)}
                                                onBlur={(e) => setCustomPrice(e.target.value)}
                                                placeholder='19€'
                                            />
                                        </Box>
                                        <Box>
                                            <Button
                                                colorScheme='blue'
                                                isDisabled={!customPrice > 0 ? true : false} type='submit'>
                                                {!customPrice > 0 ?
                                                    'Saisissez le montant de votre choix'
                                                    : 'Accéder au paiement'
                                                }
                                            </Button>
                                        </Box>
                                    </HStack>
                                    <FormHelperText>
                                        {customPrice > 0 ?
                                            <FormattedMessage id="donation.custom.button.label" />
                                            : null}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                            <Divider />
                            <Box>
                                <Text as='span' bgColor='pink.50' borderBottom={'solid 1px'} borderBottomColor='pink.100'>
                                    💖 Pour remercier ceux qui alimentent la caisse de bord, je distribue dés que j'en ai la possibilité des petits présents . En 2021/2022, cela a été des entrées au salon nautique, des invitations pour le Festival Sailorz... Sans promesse, je vous assure qu'à chaque fois que ce sera possible, je vous remercierai de votre soutien.
                                </Text>
                            </Box>
                            <Divider />

                            <Flex
                                mt='2rem'
                                justify={{
                                    base: 'flex-start',
                                    lg: 'flex-end'
                                }}
                                w='full'
                            >
                                <Button
                                    leftIcon={<BiX />}
                                    color='blue.900'
                                    boxShadow='sm'
                                    fontWeight='normal'
                                    px={3}
                                    py={1}
                                    fontSize={{ base: '12px', lg: '18px' }}
                                    borderBottom='solid 1px'
                                    borderBottomColor='gray.300'
                                    whiteSpace='pre-wrap'
                                    textAlign='left'
                                    _hover={{
                                        textDecoration: 'none',
                                        borderBottomColor: 'blue.brand'
                                    }}
                                    onClick={handleRefuse}
                                >
                                    <FormattedMessage id="donation.refuse.button.label" />
                                </Button>
                            </Flex>
                        </Stack>
                        :
                        <Flex justify='center' border="dashed 1px" borderColor='gray.300' borderRadius='sm' p={10} color='blue.600'><Spinner size='sm' /></Flex>
                    }



                </Stack>
            </Flex>
        </LayoutFunnel>
    )
}

export default withLocation(TipPaymentTemplate)